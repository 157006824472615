.e213_485 { 
	width:307px;
	height:167px;
	/* position:absolute; */
}
.ei213_485_213_484 { 
	width:307px;
	height:167px;
	position:absolute;
	
}
.ei213_485_213_482 { 
	color:rgba(0, 0, 0, 0.3100000023841858);
	width:307px;
	height:167px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:DM Mono;
	text-align:center;
	font-size:128px;
	letter-spacing:0;
	text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
             1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}