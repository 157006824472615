.Projects-Card-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Projects-Card {
    height: 600px;
    width: 650px;   
}
.project-info-inline {
    display: flex;
    justify-content: space-between;
}
.form-check-label {
    padding: 0 4px 0 5px;
}
.project-tags {
    display: flex;
    align-items: center;
}
.project-tag {
    display: flex;
    align-items: center;
    padding: 0 2px 0 2px;
}
.project-subproject-block {
    display: none;
}