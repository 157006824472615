.main-panel {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: "Consolas";
}

.main-panel-fab-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin: 15px;
}

.main-panel-fab-container > .main-panel-fab {
    margin-right: 15px;
}