/* .vis-item-visible-frame {
  z-index: 111111;
  position: absolute;
  top: 0;
} */

/*  Overflow settings of itmes */
.vis-item .vis-item-overflow {
  overflow: visible !important;
}

.vis-item.green {
  background-color: rgb(0, 255, 34);
  border-color: green;
  /* color: white; */
}
.vis-item.blue {
  background-color: rgb(0, 225, 255);
  border-color: rgb(3, 255, 242);
  /* color: white; */
}
.vis-item.red {
  /* background-color: red;
  border-color: darkred; */
  /* color: white; */
  font-family: monospace;
  box-shadow: 0 0 10px gray;
}

#timeline-wrapper {
  background-color: aliceblue;
}

/* toggle tasks list  */
/* .timeline-tasksList-visible {
  width: 800px;
  height: 800px;
  float: right;
  position: absolute;
  right: 6px;
  top: 45px;
  z-index: 1;
  overflow-y: hidden;
} */
