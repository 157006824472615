.toggle_switch_text {
  display: flex;
  flex-direction: row;
  border-radius: 0.2rem;
  padding: 0.2rem;
  font-style: oblique;
  margin: 0.2rem;
  background-color: #be00e4;
  align-items: center;
  justify-content: center;
}
#toggle-btn {
  /* background-color: #babeda; */
  background: linear-gradient(
    to right,
    #b294ff,
    #57bde6,
    #feffb8,
    #57e6e6,
    #b294ff,
    #57e6e6
  );
  background-size: 500% auto;
  animation: gradient 3s linear infinite;
  /* align-items: center; */
  align-self: center;
  border-radius: 5px;
  margin: 3px 6px 3px 6px;
  /* padding: 0.1rem; */
}