.story-list {
  height: 700px;
  overflow: scroll;
}
.story-list-item {
  height: 20px;
  display: flex;
}
.mini-story-list-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
