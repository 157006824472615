.App {
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* flex display */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.main-wrapper {
  /* height: calc(100vh - 41px - 20px); */
  height: 100%;
  overflow-y: hidden;
  /* overflow-y: scroll; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
#Admin-Section {
  height: 100%;
  width: 100%;
}

.bottom-footer {
  height: 20px;
  background-color: rgb(255, 255, 255);
}
