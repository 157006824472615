.analyser-wrapper {
  /* height: calc(100% - 41px - 20px ); */
  /* width: calc( 100% - ; */
}
.ana-item {
  background-color: aliceblue;
  width: 100%;
  margin-bottom: 0.25rem;
}
.ana-items {
  background-color: aliceblue;
  width: 100%;
  height: auto;
  overflow: scroll;
  /* margin-left: 0.5rem; */
}
.analyser-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-top:4px ; */
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
  z-index: 1000;
}
.analyser-content {
  display: flex;
  flex-direction: row;
}
.analyser-content-clip {
  background-color: aliceblue;
  min-width: 50%;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  /* overflow-y: hidden; */
}
.analyser-content-side {
  width: auto;
  max-width: 50%;
  height: calc(100vh - 100px);
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  /* overflow: scroll; */
}
.max_z {
  /* z-index: 100000; */
}
.analyser-links-list {
  display: flex;
  flex-direction: column;
}
.analyser-links-list-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.analyser-links-mass-adder {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}
.analyser-words-list {
  display: flex;
  flex-direction: column;
  min-width: 220px;
}
.analyser-words-list-title {
  /* display: inline;
  align-items: center; */
}
.new-mass-link-res {
  display: flex;
}