.guard-tag {
    display: flex;
    flex-direction: row;
    margin: 2px;
    font-size: 0.8rem;
    border: #8a4c61 2px solid;
    border-radius: 5px;
}

.guard-tag-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3px 5px;
    margin: -1px;
    color: white;
    background-color: #8a4c61;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.guard-tag-signature {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 3px 5px;
}

.guard-tag p {
    margin: 0px;
}

.guard-tag .default-node-argument {
    padding: 3px;
    margin: 0px;
    margin-left: 4px;
    line-height: 1rem;
}