/* Day */
.day-main-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Items adder */
.day-item-adder-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.day-item-adder-inline {
  display: flex;
  flex-direction: row;
}
/* Items */
.day-item-wrapper {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
}
.day-item-inline {
  display: flex;
  flex-direction: row;
}
