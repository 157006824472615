.search-layout {
  width: 100%;
  height: 40px;
}

.extra-search {
  width: 100%;
  /* padding: 0.2rem; */
  display: flex;
  flex-direction: column;
}
.extra-search .input-group {
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: aliceblue; */
  flex-direction: row;
  justify-content: space-between;
}
.extra-search .input-group .form-outline {
  width: calc(90%);
  background-color: var(--search-bar-reuslt-color);
}
.extra-search .input-group .btn {
  width: 10%;
}
.wrapper-search-result {
  position: absolute;
  display: flex;
  align-self: center;
  background-color: #383838;
  min-width: 90vw;
  height: auto;
  max-height: 85vh;
  /* margin-top: 45px; */
  margin: 45px 198px auto 0px;
  z-index: var(--z-index-search-results);
  flex-direction: column;
}
.wrapper-template-search {
  display: flex;
  height: 30px;
  flex-direction: row;
  justify-content: center;
}
.wrapper-template-search-item {
  width: auto;
  height: 100%;
  border: 3px solid grey;
  border-radius: 5px;
  background-color: white;
  margin: 0 2px;
  padding: 0 3px;
}
.extra-search-results {
  display: inherit;
  margin: 0.2rem 0.5rem 0 0.35rem;
}
.extra-search-results .srch-res-stat {
  width: auto;
  height: 50px;
  border: 3px solid grey;
  border-radius: 5px;
  background-color: white;
  margin: 0 2px;
  padding: 0 3px;
  font-size: 14px;
}
.extra-search-results .srch-ress {
  overflow-y: auto;
  max-height: 60vh;
}
/* .extra-search-results . */

.srch-indi-res {
  border: 2px solid rgb(0, 0, 0);
  border-radius: 4px;
  margin: 0.2rem 0.35rem;
  background-color: white;
}
.srch-indi-res-topic {
  background-color: rgb(138, 138, 138);
  /* margin:; */
}
.srch-indi-res-text {
  margin: 0 0.3rem;
}
