.app-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.reactflow-wrapper {
  height: 100%;
}

.MuiGrid-root.sidebar {
  display: flex;
  flex-direction: column;
  border-right: rgb(200, 200, 200) solid 1px
}

.sidebar.read-only {
  opacity: 0.5;
}

.sidebar-tab {
  flex-grow: 1;
  border-bottom: rgb(200, 200, 200) solid 1px
}

.mistreevous-icon {
  display: block;
  width: 36px;
  height: 36px;
  margin-right: 20px;
}