.dict-outer-wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: auto;
  background-color: rgb(32, 33, 36);
  padding: 1rem;
  border-radius: 0.5rem;
}

.dict-header-wrapper {
  display: flex;
  flex-direction: row;
}
.dict-header-audio {
  height: 100%;
  cursor: pointer;
  margin-left: 0.5rem;
  color: #ffffff;
  /* display: flex;
    justify-content: center; */
}

.dict-header-text {
  font-family: Google Sans, arial, sans-serif;
  color: #e8eaed;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  vertical-align: top;
}
.dict-header-text-mini {
  margin-left: 0.5rem;
  font-family: Google Sans, arial, sans-serif;
  color: #e8eaed;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  vertical-align: top;
}
.dict-content-wrapper {
  display: flex;
  flex-direction: column;
}
.dict-content-item-header {
  word-wrap: break-word;
  color: #9aa0a6;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.dict-content-item-info-wrapper {
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  list-style: armenian;
  color: #e8eaed;
  margin-bottom: 0.25rem;
}
.dict-content-item-info-one {
  color: #ffffff;
  cursor: default;
  word-wrap: break-word;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: inline;
}
.dict-content-item-info-two {
  color: #bdc1c6;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.dict-content-items {
  padding-left: 2rem;
}
