.input_style {
  background: linear-gradient(
    to right,
    #b294ff,
    #57bde6,
    #feffb8,
    #57e6e6,
    #b294ff,
    #57e6e6
  );
  background-size: 500% auto;
  animation: gradient 3s linear infinite;
  margin: 0px 6px 2px 0px;
  border-radius: 10%;
  width: 100%;
  height: 40px;
  max-height: 40px;
}

.input_style input {
  /* border-radius:5%; */
  border-bottom: none;
  margin-bottom: 3px;
  width: 100%;
  height: 90%;
  background: rgba(57, 63, 84, 0.8);
}
.input_style.white input {
  background: aliceblue;
}

.text_with_value {
  display: flex;
}
.text-wrapper {
  padding: 0.25rem;
}
.text-input-wrapper {
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
}
.text-input-search-results {
  position: absolute;
  margin-top: 40px;
  padding: 0 15px 0 0;
  background-color: white;
  width: auto;
  border-radius: 0.5rem;
  max-height: 30vh;
  overflow-y: scroll;
}
